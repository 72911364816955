import { Attributes, LookupBase, NeoModel, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class TradeCost extends APIModelBase {

    @Attributes.Float()
    public strateCostPercentage: number = 0;

    @Attributes.Float()
    public strateCostMin: number = 0;

    @Attributes.Float()
    public strateCostMax: number = 0;

    @Attributes.Float()
    public investorProtectionLevy: number = 0;

    @Attributes.Float()
    public vat: number = 0;

    @Attributes.Float()
    public stt: number = 0;

    @Attributes.Float()
    public csdpCost: number = 0;

    @Attributes.Float()
    public brokerage: number = 0;

    @Attributes.Float()
    public moneyMarkedRate: number = 0;

    @Attributes.Float()
    public minLumpSumAmount: number = 0;

    @Attributes.Float()
    public annualContributionLimit: number = 0;

    @Attributes.Integer()
    public instrumentMinimumTradeValue: number = 0;

    @Attributes.Integer()
    public maximumTradeValue: number = 0;

    // Client only properties / methods

    public getBrokerage(tradeAmount: number): number {
        return Math.round(this.brokerage * tradeAmount * 100) / 100;
    }

    public getStrateFee(tradeAmount: number): number {
        return Math.round(Math.min(this.strateCostMax, Math.max(this.strateCostMin, tradeAmount * this.strateCostPercentage)) * 100) / 100;
    }

    public getFees(tradeAmount: number, feeMultiplier?: number | null): TradeFees {
        // trade fees multiplier for model portfolios, as multiple instruments are traded
        const effectiveMultiplier = Math.max(1, feeMultiplier ?? 1);
        const brokerage = this.getBrokerage(tradeAmount) * effectiveMultiplier;
        const strateFee = this.getStrateFee(tradeAmount) * effectiveMultiplier;

        const fees = new TradeFees();
        fees.brokerage = brokerage;
        fees.strateFee = strateFee;
        fees.vat = Math.round((brokerage + strateFee) * this.vat * 100) / 100;
        return fees;
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New trade cost";
        } else {
            return "Trade Cost";
        }
    }
}

@NeoModel
export class TradeFees extends LookupBase {

    @Attributes.Float()
    public brokerage: number = 0;

    @Attributes.Float()
    public strateFee: number = 0;

    @Attributes.Float()
    public vat: number = 0;

    @Attributes.Float()
    public get totalFees() {
        return this.brokerage + this.strateFee + this.vat;
    }
}