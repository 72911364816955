import { FileUtils, List, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { InfoPanelController } from "../../../App/Components/InfoPanel";
import { PurchaseInstrumentDetail } from "../../../Models/Instruments/InstrumentDetail";
import { RecurringTradeRequest, RecurringTradeRequestDetail, RecurringTradeRequestPost, TAUDebitOrder } from "../../../Models/Base/RecurringTradeRequest";
import { NotificationDuration } from "../../../Models/Enums/NotificationDuration";
import InvestorLinkedPortfolioLookup from "../../../Models/Investors/InvestorLinkedPortfolioLookup";
import { AppService, Types } from "../../../Services/AppService";
import Instrument from "../../../Models/Instruments/Instrument";
import { InvestableType } from "../../../Models/Base/Enums/InvestableType";

@NeoModel
export default class InvestmentInfoVM extends Views.ViewModelBase {

    constructor(
        public portfolio: InvestorLinkedPortfolioLookup,
        public panelController: InfoPanelController,
        private apiClient = AppService.get(Types.ApiClients.PortfolioApiClient),
        private notificationService = AppService.get(Types.Neo.UI.GlobalNotifications),
        private debitOrderApiClient = AppService.get(Types.ApiClients.RecurringTradeRequestApiClient),
        private investorService = AppService.get(Types.Services.CurrentInvestorService),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        public documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient),
        public otpService = AppService.get(Types.Services.OtpService)) {
        super();
    }

    public updateReInvestDividends() {
        this.taskRunner.run(async () => {
            await this.apiClient.reinvestDividends({portfolioTypeID: this.portfolio.portfolioTypeID, reinvestDividends: this.portfolio.pendingReinvestDividends});

            this.portfolio.reinvestDividends = this.portfolio.pendingReinvestDividends;

            this.notificationService.addSuccess(this.portfolio.portfolioType + " investment", "Dividend settings saved successfully.", NotificationDuration.Standard);
        });
    }

    // Debit orders.

    public debitOrderDetails: RecurringTradeRequest | null = null;
    public TAUDebitOrderList = new List(TAUDebitOrder);
    public editingDebitOrder: RecurringTradeRequestPost | null = null;
    public instrument: Instrument | null = null
    public downloadTask = AppService.get(Types.Neo.TaskRunner);
  
    public async initialise() {

        const result = await this.taskRunner.waitForAll({
            trades : this.debitOrderApiClient.getRecurringTradeRequestDetails(this.portfolio.portfolioTypeID),
            debitOrders: this.debitOrderApiClient.getHistoricalDebitOrderList(this.portfolio.portfolioTypeID)
        }
        );
        if (result) {
            this.debitOrderDetails = RecurringTradeRequest.fromJSObject<RecurringTradeRequest>(result.trades.data);
            this.TAUDebitOrderList?.set(result.debitOrders.data)
        }
    }

    public changeDebitOrderDay(day: number, otp: string) {

        this.taskRunner.run(async () => {
 
            await this.debitOrderApiClient.postRecurringTradeRequest({
                portfolioTypeID: this.portfolio.portfolioTypeID,
                debitOrderDay: day,
                sourceOfFundsID: this.debitOrderDetails!.sourceOfFundsID,
                sourceOfFundsAdditionalInfo: this.debitOrderDetails!.sourceOfFundsAdditionalInfo,
                selectedInstruments: this.debitOrderDetails!.instruments.map(c => ({instrumentID: c.instrumentID, amount: c.amount})) as PurchaseInstrumentDetail[],
                otp: otp})

            // Update previously loaded data.
            this.debitOrderDetails!.debitOrderDay = day;
            this.debitOrderDetails!.hasSentOtp = false;
            this.debitOrderDetails!.otp = "";
            this.notificationService.addSuccess(this.portfolio.portfolioType + " investment", "Debit order date updated successfully.", NotificationDuration.Standard);
        });
    }

    public async editDebitOrder(debitOrder: RecurringTradeRequestDetail) {
        let instrumentDetail = new PurchaseInstrumentDetail();

        this.editingDebitOrder = RecurringTradeRequestPost.mapFrom<RecurringTradeRequestPost>(debitOrder);
        // adding this manually since mapFrom() won't because of the property name mismatch
        instrumentDetail.instrumentID = debitOrder.instrumentID;
        instrumentDetail.amount = debitOrder.amount;

        this.editingDebitOrder.selectedInstruments.push(instrumentDetail);
        this.editingDebitOrder.tradeCosts = await this.appDataCache.tradeCosts.getDataAsync();

        this.instrument = (await this.investorService.portfolioData?.fetchInstruments()!).find(c => c.instrumentID === debitOrder.instrumentID)!;
        this.editingDebitOrder.investableType = this.instrument.investableTypeID;
        this.editingDebitOrder.minimumInvestmentAmount = this.instrument.minimumInvestmentAmount;
        this.debitOrderEstimatedUnits();
        this.panelController.forward("EditDebitOrder");
    }

    public debitOrderEstimatedUnits() {
        let currentInstrument = this.editingDebitOrder!.selectedInstruments[0]
        
        if (this.instrument) {
            currentInstrument.setTradeValues(this.instrument);
        }

         var tradeFees = this.editingDebitOrder!.tradeCosts!.getFees(this.editingDebitOrder!.amount, currentInstrument.tradeCostMultiplier)
         this.editingDebitOrder!.fees.brokerage = tradeFees.brokerage;
         this.editingDebitOrder!.fees.strateFee = tradeFees.strateFee;
         this.editingDebitOrder!.fees.vat = tradeFees.vat;

        let estimatedUnitsCalculation = (this.editingDebitOrder!.amount - this.editingDebitOrder!.fees.totalFees) / this.instrument!.buyPrice;

        if (this.instrument?.investableTypeID === InvestableType.Instrument) {
            currentInstrument.estimatedUnits = this.instrument.buyPrice > 0 ? Math.floor(estimatedUnitsCalculation) : 0;
        } else {
            currentInstrument.estimatedUnits = 0;
        }
    }

    public async confirmChangeDebitOrder() {
        if (this.editingDebitOrder) {
            const prevOtp = await this.taskRunner.waitFor(this.otpService.sendOtp());
            if (prevOtp) {
                this.editingDebitOrder.otp = prevOtp;
                this.submitChangeDebitOrder();
            } else {
                this.editingDebitOrder.hasSentOtp = true;
            }
        }
    }

    public submitChangeDebitOrder() {

        this.taskRunner.run(async () => {
            if (this.editingDebitOrder) {
                this.editingDebitOrder!.selectedInstruments[0]!.amount = this.editingDebitOrder?.amount;

                await this.debitOrderApiClient.postRecurringTradeRequest({
                    ...this.editingDebitOrder.toJSObject(),
                    portfolioTypeID: this.debitOrderDetails!.portfolioTypeID,
                    debitOrderDay: this.debitOrderDetails!.debitOrderDay,
                    sourceOfFundsID: this.debitOrderDetails!.sourceOfFundsID,
                    sourceOfFundsAdditionalInfo: this.debitOrderDetails!.sourceOfFundsAdditionalInfo
                });
                this.otpService.storeValidOtp(this.editingDebitOrder.otp);

                // Update previously loaded data.
                const debitOrder = this.debitOrderDetails!.instruments.find(c => c.instrumentID === this.editingDebitOrder!.selectedInstruments[0].instrumentID)!;
                debitOrder.amount = this.editingDebitOrder!.amount;

                this.notificationService.addSuccess(this.portfolio.portfolioType + " investment", "Debit order updated successfully.", NotificationDuration.Standard);
                this.panelController.back();
            }
        });
    }

    public cancelDebitOrder(debitOrder: RecurringTradeRequestDetail) {

        this.taskRunner.run(async () => {

            await this.debitOrderApiClient.cancelRecurringTradeRequest({ portfolioTypeID: this.portfolio.portfolioTypeID, instrumentID: debitOrder.instrumentID });
            this.debitOrderDetails!.instruments.remove(debitOrder);

            if (this.debitOrderDetails!.instruments.length === 0) {
                this.debitOrderDetails = null;
            }

            this.notificationService.addSuccess(this.portfolio.portfolioType + " investment", "Debit order cancelled successfully.", NotificationDuration.Standard);
        });
    }

    public async downloadTradeDebitOrderMandate(contractNo: string, selfLink: string) {
        const response = await this.downloadTask.waitFor(this.documentApiClient.getTradeDebitOrderMandateDocument(contractNo, selfLink));

        FileUtils.showSaveFile(response);
    }
}