import { Attributes, List, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export class PortfolioBalance extends ApiModelBase {

    public portfolioID: number = 0;

    public portfolioTypeID: number = 0;

    public taxFreeInd: boolean = false;

    @Attributes.Float()
    public cashAvailable: number = 0.0;

    @Attributes.Float()
    public cashReserved: number = 0.0;

    @Attributes.Float()
    public totalBalance: number = 0.0;

    @Attributes.Float()
    public availableBalance: number = 0.0;

    @Rules.Required()
    @Attributes.Date()
    public valuationDate: Date | null = null;

    @Attributes.Float()
    public pendingBalance: number = 0.0;

    @Attributes.Float()
    public irr: number = 0.0;

    @Attributes.Float()
    public netContributions: number = 0.0;

    @Attributes.Float()
    public currentTaxYearContributions: number = 0.0;

    public instruments = new List(PortfolioInstrumentBalance);

    // Client only properties / methods

    public get hasInvestments() {
        return this.cashAvailable > 0 || this.instruments.length > 0;
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New portfolio balance";
        } else {
            return "Portfolio Balance";
        }
    }
}

@NeoModel
export class PortfolioInstrumentBalance extends ApiModelBase {

    public instrument: string = "";

    public instrumentID: number = 0;

    public instrumentCode: string = "";

    @Attributes.Float()
    public price: number = 0.0;

    @Attributes.Float()
    public unitsAvailable: number = 0.0;

    @Attributes.Float()
    public unitsTotal: number = 0.0;

    @Attributes.Float()
    public unitsReserved: number = 0.0;

    @Attributes.Float()
    public valueTotal: number = 0.0;

    @Attributes.Float()
    public valueReserved: number = 0.0;

    @Attributes.Float()
    public valueAvailable: number = 0.0;

    @Attributes.Float()
    public valueAccrued: number = 0.0;

    @Rules.Required()
    @Attributes.Date()
    public priceDate: Date | null = null;

    @Attributes.Float()
    public unitsPending: number = 0.0;

    @Attributes.Float()
    public valuePending: number = 0.0;

    public hasTradeCosts: boolean = false;

    @Attributes.Nullable()
    public assetClassId: number | null = null;

    @Attributes.Nullable()
    public regionId: number | null = null;

    public instrumentProviderId: number | null = null;

    public isCashInstrument: boolean = false;

    public isTradeable: boolean = true;

    public isModelPortfolio: boolean = false;

    public hasGraphDataAvailable: boolean = true;

    public underlyingInstrumentsCount: number = 0;

    // Client only properties / methods

    public get valueInclPending() {
        return this.valueTotal + this.valuePending;
    }

    public get availableValueInclPending() {
        return this.valueAvailable + this.valuePending;
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.instrument) {
            return "New portfolio instrument balance";
        } else {
            return this.instrument;
        }
    }
}