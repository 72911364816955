import { EnumHelper } from "@singularsystems/neo-core";

export enum OtpType {
    Email = 1,
    CellNo = 2,
    Both = 3
}

EnumHelper.decorateEnum(OtpType, e => {
    e.describe(OtpType.Email, "Email", "email");
    e.describe(OtpType.CellNo, "SMS", "SMS");
    e.describe(OtpType.Both, "Email and SMS", "email and SMS");
});